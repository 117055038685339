<template>
  <div class="about">
    <!-- 网站顶部 -->
    <OfficialOtherHead :homePageSetting="homePageSetting" />
    <!-- detail -->
    <div class="main">
      <!-- 内容 -->
      <div class="panel">
        <div class="detail-breadcrumb">
          <a target="_blank" v-if="breadcrumbList && breadcrumbList.length>1" :href="getLayout(breadcrumbList[1])">{{breadcrumbList[1].title}}</a>
          {{breadcrumbList && breadcrumbList.length>1?'/':''}}
          <a target="_blank" v-if="breadcrumbList && breadcrumbList.length>0" :href="getLayout(breadcrumbList[0])">{{breadcrumbList[0].title}}</a>
        </div>
        <div class="container">
          <!-- 标题 -->
          <div class="content-title">
            <div class="content-box">
              <h1>{{ dataModel.title }}</h1>
              <div class="info">
                <span v-if="dataModel.author"
                  >作者：{{ dataModel.author }}</span
                >
                <span>来源：{{ dataModel.sourceFrom }}</span>
                <span>浏览次数：{{ dataModel.readCount }}</span>
                <span>日期：{{ dataModel.publishTimeStr }}</span>
              </div>
            </div>
          </div>
          <div class="content" v-html="dataModel.content"></div>
          <div class="share" v-if="false">
            <div class="share-item wecaht"><i></i></div>
            <div class="share-item weibo"><i></i></div>
            <div class="share-item friend"><i></i></div>
          </div>
          <div class="link-box">
            <div
              class="link-box-item"
              v-if="dataModel.prevLinkArticle && dataModel.prevLinkArticle.id"
            >
              <span>上一篇：</span
              ><a
                target="_blank"
                :href="'/detail?id=' + dataModel.prevLinkArticle.id"
                >{{ dataModel.prevLinkArticle.title }}</a
              >
            </div>
            <div
              class="link-box-item"
              v-if="dataModel.nextLinkArticle && dataModel.nextLinkArticle.id"
            >
              <span>下一篇：</span
              ><a
                target="_blank"
                :href="'/detail?id=' + dataModel.nextLinkArticle.id"
                >{{ dataModel.nextLinkArticle.title }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end detail -->
    <!-- 网站底部 -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting, setSeo } from "../api/defaultDataModel";
export default {
  name: "Detail",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      homePageSetting: getHomePageSetting(),
      breadcrumbList:[],
      dataModel: {
        seoTitle: undefined,
        seoDescription: undefined,
        seoKeyword: undefined,
        title: undefined,
        bannerImgUrl: undefined,
        content: undefined,
        summary: undefined,
        picture: undefined,
        miniature: undefined,
        author: undefined,
        readCount: undefined,
        sourceFrom: undefined,
        publishTimeStr: undefined,
        articleListNameList: [],
        prevLinkArticle: {
          id: undefined,
          title: undefined,
        },
        nextLinkArticle: {
          id: undefined,
          title: undefined,
        },
      },
    };
  },
  methods: {
    onSubmit() {
    },
    getLayout(layout) {
      if (!layout) {
        return "/";
      }
      //文字列表
      if (1 === layout.layoutType) {
        return `plainTextNews?id=${layout.id}`;
      }
      //小图列表
      if (2 === layout.layoutType) {
        return `smallPictureNews?id=${layout.id}`;
      }
      //大图列表
      if (3 === layout.layoutType) {
        return `bigPictureNews?id=${layout.id}`;
      }
      //竖图列表
      if (4 === layout.layoutType) {
        return `verticalPictureNews?id=${layout.id}`;
      }
      return "/";
    },
  },
  created() {
    //当前关于数据
    if (this.$route.query.id && /^\d{15,}$/.test(this.$route.query.id)) {
      this.$http
        .get(this.TzConfig.BASE_URL + "/h5/article/getById", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          if (8000 === res.data.code) {
            this.dataModel = res.data.data;
            this.breadcrumbList = [...this.dataModel.articleClassifyList]
            this.breadcrumbList.reverse()
            setSeo(
              this.dataModel.seoTitle,
              this.dataModel.seoDescription,
              this.dataModel.seoKeyword
            );
          } else {
            this.$router.push({ path: "/" });
          }
        })
        .catch((err) => {
          this.$router.push({ path: "/" });
        });
    } else {
      this.$router.push({ path: "/" });
    }

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
        }
      })
      .catch((err) => {
      });
  },
};
</script>
<style scoped>
@charset "utf-8";
#app {
  background: #f4f6f8;
}
.main {
  margin: 88px auto 0;
  width: 100%;
}
.panel {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  padding: 0 0 68px;
  width: 100%;
}
.detail-breadcrumb {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.5);
  line-height: 28px;
}

.detail-breadcrumb a{
  margin: 0 10px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.5);
  line-height: 28px;
}
.detail-breadcrumb a:last-of-type{
  margin: 0 0 0 10px;
}
.content-title {
  position: relative;
  height: auto;
  overflow: hidden;
}
.content-box {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 64px;
  width: 100%;
  text-align: center;
}
.content-box h1 {
  font-size: 40px;
  line-height: 64px;
}
.content-box .info {
  margin: 13px 0 0;
}
.content-box .info span {
  padding: 0 16px;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  color: rgba(33, 33, 33, 0.5);
}
.container {
  margin: 0 auto;
  padding: 46px 100px;
  max-width: 1200px;
  background: #fff;
}
.content {
  width: 100%;
}
.content p {
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: 36px;
}
.content img {
  margin: 0 auto 16px;
  display: block;
  max-width: 100%;
  height: auto;
}
.content span {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 36px;
  color: rgb(0 0 0 /50%);
}
.share {
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
}
.share:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
}
.share-item {
  position: relative;
  z-index: 2;
  padding: 0 17px;
  display: inline-block;
  height: 32px;
  background: #fff;
}
.share-item i {
  display: block;
  width: 32px;
  height: 32px;
  opacity: 0.64;
}
.wecaht i {
  background: url(../assets/images/share-wechat.png) no-repeat center/32px;
}
.weibo i {
  background: url(../assets/images/share-weibo.png) no-repeat center/32px;
}
.friend i {
  background: url(../assets/images/share-friend.png) no-repeat center/32px;
}
.link-box {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding: 10px 0;
  width: 100%;
  margin:30px auto 0;
}
.link-box-item {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
}
.link-box-item span {
  display: inline-block;
  padding: 0 6px 0 0;
  font-size: 18px;
  line-height: 36px;
}
.link-box-item a {
  font-size: 18px;
  line-height: 36px;
}
.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container,
  .detail-breadcrumb {
    max-width: auto;
    width: 90%;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .content-box {
    width: 100%;
  }
  .panel {
    padding: 0 0 40px;
  }
  .container {
    padding: 30px 50px;
  }
}

@media (max-width: 768px) {
  .content-box .info span {
    padding: 0 8px;
  }
}

@media (max-width: 640px) {
  #app {
    background: #fff;
  }
  .panel {
    margin: 0;
    padding: 0;
  }
  .detail-breadcrumb {
    display: none;
  }
  .content-title {
    height: auto;
  }
  .content-box {
    padding: 0.5rem 0 0.64rem;
  }
  .content-box h1 {
    height: auto;
    font-size: 0.64rem;
    line-height: 0.96rem;
  }
  .content-box .info {
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0.3rem 0 0;
  }
  .content-box .info span {
    padding: 0 0.2rem;
    width: 50%;
    font-size: 0.28rem;
    line-height: 0.56rem;
    white-space: normal;
  }
  .container {
    margin: 0;
    padding: 0 0.4rem 0.46rem;
    width: 100%;
  }
  .content p {
    margin: 0 auto 0.3rem;
    font-size: 0.36rem;
    line-height: 0.64rem;
  }
  .content img {
    margin: 0 auto 0.2rem;
  }
  .content span {
    font-size: 0.14rem;
    line-height: 0.48rem;
    color: #414141;
  }
  .share-item {
    padding: 0 0.34rem;
    height: 0.64rem;
  }
  .share-item i {
    display: block;
    width: 0.64rem;
    height: 0.64rem;
  }
  .wecaht i {
    background: url(../assets/images/share-wechat.png) no-repeat center/.64rem;
  }
  .weibo i {
    background: url(../assets/images/share-weibo@2x.png) no-repeat center/.64rem;
  }
  .friend i {
    background: url(../assets/images/share-friend@2x.png) no-repeat
      center/.64rem;
  }
  .link-box {
    display: none;
  }
}
</style>
